import Button from "../atoms/Button";
import Heading from "../atoms/Heading";
import IconWithText from "../molecules/IconWithText";
import SidebarListItems from "../molecules/SidebarListItems";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { getAuth, removeAuth } from "../services/identity.service";
import Text from "../atoms/Text";
import Image from "next/image";
import { useNutritionistsPatients } from "services/nutritionistsQueries";
import { useEffect, useRef, useState } from "react";
import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db, auth as firebaseAuth } from "services/firebase.service";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useAdminPatients } from "services/hc.useAdminPatientQuery";
import { ROLES, patientNotificationType } from "lib/utils";
import { useGetLeaveRequestCount, useGetPatientsCount, useGetScheduledCallCount, useGetUnreadFlareupCount, useGetUnreadRednessCount } from "services/notificationQueries";
import { SIDEBAR_ROLES } from "lib/constants";
import { logout } from "services/login.service";
import { getUserById } from "services/user.service";
import { useAlertNotificationTotalCountQuery } from "services/alert-notificationQuery";

const Sidebar = ({ className, isSidebarOpen, toggleSidebar }) => {
  const router = useRouter();
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState({});
  const [pageOptions, setPageOptions] = useState({
    offset: 0,
    limit: 100,
    id: auth?.id,
    search: '',
    filters: ''
  });


  const { isSuccess, data: allNutritionistsPatients } =
    auth?.role === "admin"
      ? useAdminPatients(pageOptions)
      : useNutritionistsPatients(pageOptions);
   const [userProfileUrl, setUserProfileUrl] = useState('');
  const [userChatCount, setChatCount] = useState(0);
  const { data: flareupCount } = useGetUnreadFlareupCount({ hcId: auth?.id, status: 'UNREAD', role: auth?.role });
  const { data: ibsFlareUpCount } = useGetUnreadFlareupCount({ hcId: auth?.id, status: 'UNREAD', role: auth?.role, forIbs: true })
  const { data: rednessCount } = useGetUnreadRednessCount({ hcId: auth?.id, status: 'UNREAD', role: auth?.role })
  const { data: scheduledCallCount } = useGetScheduledCallCount({ scheduledBy: auth?.id, callStatus: 'upcoming', role: auth?.role })
  const { data: callbackRequestCount } = useGetScheduledCallCount({ scheduledBy: auth?.id, callStatus: 'callback', role: auth?.role })
  const { data: leaveRequestCount } = useGetLeaveRequestCount({ status: 'pending', role: auth?.role })
  const { data: patientCount } = useGetPatientsCount({ type: patientNotificationType[auth?.role], hcId: auth?.role === ROLES.ADMIN ? '' : auth?.id, status: 'NEW', role: auth?.role })
  const { data: alertNotificationCount } = useAlertNotificationTotalCountQuery({ hcId: auth?.id, role: auth?.role })
  const unsubscribeRef = useRef();
  //firebase function to get chat count logged in user
  const getUnreadMessageCount = async (id) => {
    const q = query(collection(db, "rooms"), where("userIds", "array-contains", id), where("isRead", "==", false));
    const snapShot = await getCountFromServer(q);
    setChatCount(snapShot.data()?.count);
    const chatUnsubscribe = onSnapshot(q, async (querySnapshot) => {
      const result = [];
      for (const document of querySnapshot.docs) {
        const roomData = document.data();
        roomData.id = document.id;
        const otherUserId = roomData.userIds.find(
          (userId) => userId !== currentUser.uid
        );
        roomData.userInfo = (
          await getDoc(doc(db, "users", otherUserId))
        ).data();
        result.push({
          roomData,
        });
      }
      setChatCount(result.length);
    });
    return () => chatUnsubscribe();
  };
  const getUser = async()=>{
    const response = await getUserById(auth?.id)
  const  userProfileUrl = response?.data?.entity?.profileImage
    setUserProfileUrl(userProfileUrl);
  }


  

  const getCurrentUser = async () => {
    const unsub = onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        const userDetails = (await getDoc(doc(db, "users", user.uid))).data();
        user.userInfo = userDetails;
        setCurrentUser(user);
      }
    });
    currentUser.uid && getUnreadMessageCount(currentUser.uid);

    unsubscribeRef.current = unsub;
  };
  useEffect(() => {
    getUser();
    getCurrentUser();
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser?.uid || auth?.role === ROLES.ADMIN || auth?.role === ROLES.CRM) return;
    getUnreadMessageCount(currentUser.uid); // Call it initially

    const intervalId = setInterval(() => {
      getUnreadMessageCount(currentUser.uid); // 60000 milliseconds = 1 minute
    }, 60000);

    return () => clearInterval(intervalId);
  }, [currentUser])


  const sideBarDetails =
    auth?.role === "admin"
      ? [
        {
          menuActive: router.pathname.startsWith("/notifications"),
          menuItem: (
            <div className="flex items-center gap-2">
              <p>Notifications</p>
              <span className="h-8 w-8 flex items-center justify-center bg-secondary-900 text-white text-xs rounded-full">
                {alertNotificationCount?.data?.entity ?? 0}
              </span>
            </div>
          ),
          menuIconNormalSrc: "/images/icons/notification.svg",
          menuIconActiveSrc: "/images/icons/notifications.svg",
          menuIconAlt: "Notifications",
          onClick: () => router.push("/notifications"),
        },
        // {
        //   menuActive: false,
        //   menuItem: "Dashboard",
        //   menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
        //   menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
        //   menuIconAlt: "Dashboard",
        //   onClick: () => { },
        // },
        // {
        //   menuActive: router.pathname.startsWith("/patient"),
        //   menuItem: "Patient Management",
        //   menuIconNormalSrc: "/images/icons/icon-patient-b.svg",
        //   menuIconActiveSrc: "/images/icons/icon-patient-w.svg",
        //   menuIconAlt: "Patient Management",
        //   onClick: () => router.push("/patient"),
        // },
        {
          menuActive: router.pathname.startsWith("/doctors"),
          menuItem: "Doctor Management",
          menuIconNormalSrc: "/images/icons/icon-stethoscope-b.svg",
          menuIconActiveSrc: "/images/icons/icon-stethoscope-w.svg",
          menuIconAlt: "Doctor Management",
          onClick: () => router.push("/doctors"),
        },
        {
          menuActive: router.pathname.startsWith("/admin/users"),
          menuItem: "Users",
          menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
          menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
          menuIconAlt: "Dashboard",
          onClick: () => router.push("/admin/users"),
        },
        {
          menuActive: router.pathname.startsWith("/admin/patient"),
          menuItem: (
            <div className="flex items-center gap-2">
              <p>Patients</p>
              <span className="h-8 w-8 flex items-center justify-center bg-secondary-900 text-white text-xs rounded-full">
                {patientCount?.data?.entity ?? 0}
              </span>
            </div>
          ),
          menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
          menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
          menuIconAlt: "Dashboard",
          onClick: () => router.push("/admin/patient"),
        },
        {
          menuActive: router.pathname.startsWith("/diet-template"),
          menuItem: "Diet Template",
          menuIconNormalSrc: "/images/icons/icon-stethoscope-b.svg",
          menuIconActiveSrc: "/images/icons/icon-stethoscope-w.svg",
          menuIconAlt: "Diet Template",
          onClick: () => router.push("/diet-template"),
        },
        {
          menuActive: router.pathname.startsWith("/schedule-management"),
          menuItem: "Schedule Management",
          menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
          menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
          menuIconAlt: "Schedule Management",
          onClick: () => router.push("/schedule-management"),
        },
        {
          menuActive: router.pathname.startsWith("/leave-management"),
          menuItem: <div className='flex items-center gap-2'>
            <p>Leave Requests</p>
            <span
              className='h-6 w-6 flex items-center justify-center bg-secondary-900 text-white rounded-full'>
              {leaveRequestCount?.data?.entity ?? 0}
            </span>
          </div>,
          menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
          menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
          menuIconAlt: "Leave Management",
          onClick: () => router.push("/leave-management"),
        },
      ]
      : auth?.role === "crm"
        ? [
          {
            menuActive: router.pathname.startsWith("/notifications"),
            menuItem: (
              <div className="flex items-center gap-2">
                <p>Notifications</p>
                <span className="h-8 w-8 flex items-center justify-center bg-secondary-900 text-white text-xs rounded-full">
                  {alertNotificationCount?.data?.entity ?? 0}
                </span>
              </div>
            ),
            menuIconNormalSrc: "/images/icons/notification.svg",
            menuIconActiveSrc: "/images/icons/notifications.svg",
            menuIconAlt: "Notifications",
            onClick: () => router.push("/notifications"),
          },
          {
            menuActive: router.pathname.startsWith("/crm/patients"),
            menuItem: "All Patients",
            menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
            menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
            menuIconAlt: "all patients",
            onClick: () => router.push("/crm/patients"),
          },
          // {
          //   menuActive: router.pathname.startsWith("/chat-room"),
          //   menuItem: (
          //     <div className="flex gap-2">
          //       <p>Chats</p>
          //       <span className="h-8 w-8 flex items-center justify-center bg-secondary-900 text-white text-xs rounded-full">
          //         {userChatCount}
          //       </span>
          //     </div>
          //   ),
          //   menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
          //   menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
          //   menuIconAlt: "Chat",
          //   onClick: () => router.push("/chat-room"),
          // },
          {
            menuActive: router.pathname.startsWith("/calls"),
            menuItem: (<div className="flex items-center gap-2">
              <p>Patient Calls</p>
              <span className="h-8 w-8 text-xs flex items-center justify-center bg-secondary-900 text-white rounded-full">
                {scheduledCallCount?.data?.entity ?? 0}
              </span>
            </div>),
            menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
            menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
            menuIconAlt: "Calls",
            onClick: () => router.push("/calls"),
          },
          {
            menuActive: router.pathname.startsWith("/crm/assigned-patients"),
            menuItem: (
              <div className="flex items-center gap-2">
                <p>Assigned Patients</p>
                <span className="h-8 w-8 flex items-center justify-center bg-secondary-900 text-white text-xs rounded-full">
                  {patientCount?.data?.entity ?? 0}
                </span>
              </div>
            ),
            menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
            menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
            menuIconAlt: "assigned patients",
            onClick: () => router.push("/crm/assigned-patients"),
          },
          {
            menuActive: router.pathname.startsWith("/non-working-days"),
            menuItem: "Non Working Days",
            menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
            menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
            menuIconAlt: "Non working days",
            onClick: () => router.push("/non-working-days"),
          },
        ]
        : auth?.role === "uv coach"
          ? [
            {
              menuActive: router.pathname.startsWith("/hc"),
              menuItem: (
                <div className="flex items-center gap-2">
                  <p>Patients</p>
                  <span className="h-8 w-8 flex items-center justify-center bg-secondary-900 text-white text-xs rounded-full">
                    {patientCount?.data?.entity ?? 0}
                  </span>
                </div>
              ),
              menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
              menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
              menuIconAlt: "Dashboard",
              onClick: () => router.push("/hc"),
            },
            {
              menuActive: router.pathname.startsWith("/pso-flareups",),
              menuItem: (
                <div className="flex items-center gap-2">
                  <p>PSO Flareups</p>
                  <span className="h-8 w-8 flex items-center justify-center bg-secondary-900 text-white text-xs rounded-full">
                    {flareupCount?.data?.entity ?? 0}
                  </span>
                </div>
              ),
              menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
              menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
              menuIconAlt: "Dashboard",
              onClick: () => router.push("/pso-flareups"),
            },
            {
              menuActive: router.pathname.startsWith("/redness"),
              menuItem: (
                <div className="flex items-center gap-2">
                  <p>Redness</p>
                  <span className="h-8 w-8 text-xs flex items-center justify-center bg-secondary-900 text-white  rounded-full">
                    {rednessCount?.data?.entity ?? 0}
                  </span>
                </div>
              ),
              menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
              menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
              menuIconAlt: "Redness",
              onClick: () => router.push("/redness"),
            },
            {
              menuActive: router.pathname.startsWith("/chat-room"),
              menuItem: (
                <div className="flex items-center gap-2">
                  <p>Chats</p>
                  <span className="h-8 w-8 text-xs flex items-center justify-center bg-secondary-900 text-white rounded-full">
                    {userChatCount}
                  </span>
                </div>
              ),
              menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
              menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
              menuIconAlt: "Chat",
              onClick: () => router.push("/chat-room"),
            },
            {
              menuActive: router.pathname.startsWith("/calls"),
              menuItem: (<div className="flex items-center gap-2">
                <p>Patient Calls</p>
                <span className="h-8 w-8 text-xs flex items-center justify-center bg-secondary-900 text-white rounded-full">
                  {scheduledCallCount?.data?.entity ?? 0}
                </span>
              </div>),
              menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
              menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
              menuIconAlt: "Calls",
              onClick: () => router.push("/calls"),
            },
            // {
            //   menuActive: router.pathname.startsWith("/callback-request"),
            //   menuItem: (<div className="flex items-center gap-2">
            //     <p>Callback Request</p>
            //     <span className="h-8 w-8 text-xs flex items-center justify-center bg-secondary-900 text-white rounded-full">
            //       {callbackRequestCount?.data?.entity ?? 0}
            //     </span>
            //   </div>),
            //   menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
            //   menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
            //   menuIconAlt: "Callback Request",
            //   onClick: () => router.push("/callback-request"),
            // },
            {
              menuActive: router.pathname.startsWith("/non-working-days"),
              menuItem: "Non Working Days",
              menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
              menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
              menuIconAlt: "Non working days",
              onClick: () => router.push("/non-working-days"),
            }
          ] :
          auth?.role === "psoriasis admin" ? [
            {
              menuActive: router.pathname.startsWith("/orders"),
              menuItem: "Orders",
              menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
              menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
              menuIconAlt: "Orders",
              onClick: () => router.push("/orders"),
            },
            {
              menuActive: router.pathname.startsWith("/reports"),
              menuItem: "Reports",
              menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
              menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
              menuIconAlt: "Reports",
              onClick: () => router.push("/reports"),
            },

          ]
          : [
            ...(auth?.role === "nutritionist" || auth?.role === 'psychologist'
              ? [
                {
                  menuActive: router.pathname.startsWith("/notifications"),
                  menuItem: (
                    <div className="flex items-center gap-2">
                      <p>Notifications</p>
                      <span className="h-8 w-8 flex items-center justify-center bg-secondary-900 text-white text-xs rounded-full">
                        {alertNotificationCount?.data?.entity ?? 0}
                      </span>
                    </div>
                  ),
                  menuIconNormalSrc: "/images/icons/notification.svg",
                  menuIconActiveSrc: "/images/icons/notifications.svg",
                  menuIconAlt: "Notifications",
                  onClick: () => router.push("/notifications"),
                },
              ]
              : []),
            {
              menuActive: router.pathname.startsWith("/hc"),
              menuItem: (
                <div className="flex items-center gap-2">
                  <p>Patients</p>
                  <span className="h-8 w-8 flex items-center justify-center bg-secondary-900 text-white text-xs rounded-full">
                    {patientCount?.data?.entity ?? 0}
                  </span>
                </div>
              ),
              menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
              menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
              menuIconAlt: "Dashboard",
              onClick: () => router.push("/hc"),
            },
            // {
            //   menuActive: router.pathname.startsWith("/flareups"),
            //   menuItem: (
            //     <div className="flex items-center gap-2">
            //       <p>Flareups</p>
            //       {auth?.role === "nutritionist" ? (
            //         <span className="h-8 w-8 flex items-center justify-center bg-secondary-900 text-white text-xs rounded-full">
            //           {ibsFlareUpCount?.data?.entity ?? 0}
            //         </span>
            //       ) : null}
            //     </div>
            //   ),
            //   menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
            //   menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
            //   menuIconAlt: "Dashboard",
            //   onClick: () => router.push("/flareups"),
            // },
            // {
            //   menuActive: router.pathname.startsWith("/pso-flareups",),
            //   menuItem: (
            //     <div className="flex items-center gap-2">
            //       <p>PSO Flareups</p>
            //       <span className="h-8 w-8 flex items-center justify-center bg-secondary-900 text-white text-xs rounded-full">
            //         {flareupCount?.data?.entity ?? 0}
            //       </span>
            //     </div>
            //   ),
            //   menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
            //   menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
            //   menuIconAlt: "Dashboard",
            //   onClick: () => router.push("/pso-flareups"),
            // },
            {
              menuActive: router.pathname.startsWith("/chat-room"),
              menuItem: (
                <div className="flex gap-2">
                  <p>Chats</p>
                  <span className="h-8 w-8 flex items-center justify-center bg-secondary-900 text-white rounded-full">
                    {userChatCount}
                  </span>
                </div>
              ),
              menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
              menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
              menuIconAlt: "Chat",
              onClick: () => router.push("/chat-room"),
            },
            ...(auth?.role === "nutritionist"
              ? [
                {
                  menuActive: false,
                  menuItem: "Diet Template",
                  menuIconNormalSrc: "/images/icons/icon-stethoscope-b.svg",
                  menuIconActiveSrc: "/images/icons/icon-stethoscope-w.svg",
                  menuIconAlt: "Diet Template",
                  onClick: () => router.push("/diet-template"),
                }
              ]
              : []),
            {
              menuActive: router.pathname.startsWith("/calls"),
              menuItem: (<div className="flex items-center gap-2">
                <p>Patient Calls</p>
                <span className="h-8 w-8 text-xs flex items-center justify-center bg-secondary-900 text-white rounded-full">
                  {scheduledCallCount?.data?.entity ?? 0}
                </span>
              </div>),
              menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
              menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
              menuIconAlt: "Calls",
              onClick: () => router.push("/calls"),
            },
            {
              menuActive: router.pathname.startsWith("/non-working-days"),
              menuItem: "Non Working Days",
              menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
              menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
              menuIconAlt: "Non working days",
              onClick: () => router.push("/non-working-days"),
            },
            {
              menuActive: router.pathname.startsWith("/diet-plan-queue"),
              menuItem: "Diet Plan Queue",
              menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
              menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
              menuIconAlt: "Queue",
              onClick: () => router.push("/diet-plan-queue"),
            },
            // {
            //   menuActive: router.pathname.startsWith("/callback-request"),
            //   menuItem: (<div className="flex items-center gap-2">
            //     <p>Callback Request</p>
            //     <span className="h-8 w-8 text-xs flex items-center justify-center bg-secondary-900 text-white rounded-full">
            //       {callbackRequestCount?.data?.entity ?? 0}
            //     </span>
            //   </div>),
            //   menuIconNormalSrc: "/images/icons/icon-dashboard-b.svg",
            //   menuIconActiveSrc: "/images/icons/icon-dashboard-w.svg",
            //   menuIconAlt: "Callback Request",
            //   onClick: () => router.push("/callback-request"),
            // },
          ];
  return (
    <>
      <div
        className={` fixed top-0 left-0 h-full bg-white shadow-sidebar z-40 ${isSidebarOpen
          ? "w-[284px] py-4 pl-4 rounded-l-3xl"
          : "w-[88px] p-4 rounded-3xl"
          } ${className}}`}
      >
        <div
          className={`flex justify-between gap-4 mb-6 items-center ${isSidebarOpen ? "pr-6" : ""
            }`}
        >
          {isSidebarOpen && (
            <>
              <Heading type="h4" className="text-secondary-900 font-medium">
                Bloom
              </Heading>
            </>
          )}
          <IconWithText
            className="cursor-pointer"
            iconPlacement="right"
            iconSrc={`${isSidebarOpen
              ? "/images/icons/arrow-square-left.svg"
              : "/images/icons/arrow-square-right.svg"
              }`}
            iconWidth="16"
            iconHeight="16"
            title={`${isSidebarOpen ? "CLOSE" : "OPEN"}`}
            onClick={toggleSidebar}
          />
        </div>
        <ul className="overflow-y-auto h-[calc(100vh_-_165px)]">
          {sideBarDetails.map((sidebar, idx) => (
            <SidebarListItems
              key={idx}
              menuActive={sidebar.menuActive}
              menuItem={sidebar.menuItem}
              menuIconNormalSrc={sidebar.menuIconNormalSrc}
              menuIconActiveSrc={sidebar.menuIconActiveSrc}
              menuIconAlt={sidebar.menuIconAlt}
              onClick={sidebar.onClick}
              isSidebarOpen={isSidebarOpen}
            />
          ))}
        </ul>
        {isSidebarOpen && (
          <div className="py-5 px-10 absolute bottom-0 left-0 w-full bg-white">
            <Button
              onClick={async () => {
                await signOut(firebaseAuth);
                await logout(auth.id)
                removeAuth();
                router.push("/login");
              }}
              variant="primaryBtn"
              btnClass="w-full rounded-full"
              label="Logout"
            />
          </div>
        )}
      </div>
      <div className="fixed z-30 w-full top-0 flex justify-end bg-white px-6 py-3">
        {
          <div className="flex gap-3 items-center">
            <div className="flex justify-center items-center bg-secondary-900 w-10 h-10 rounded-full ">
              <img
               src={userProfileUrl ? userProfileUrl : "/images/icons/profile-img.svg"}
                width={20}
                height={25}
                alt="profile"
                className="h-[41px] w-[40px] rounded-full"
              />
            </div>
            <div>
              <Text fontWeight="font-medium" variant="body">
                {auth?.name}
              </Text>
              <Text variant='caption' className='capitalize'>
                {SIDEBAR_ROLES[auth?.role]}
              </Text>
            </div>
          </div>
        }
      </div>
    </>
  );
};

Sidebar.defaultProps = {
  className: '',
  isSidebarOpen: true,
  toggleSidebar: () => { },
};

Sidebar.propTypes = {
  className: PropTypes.string,
  isSidebarOpen: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};

export default Sidebar;
