const permittedRoutes = {
  "uv coach": [
    "/hc",
    "/calls",
    "/redness",
    "/flareups",
    "/pso-flareups",
    "/callback-request",
    "/chat-room",
    "/calls",
    "/non-working-days",
  ],
  "psoriasis admin": ["/orders", "/reports"],
  doctor: ["/doctor-panel"],
  admin: [
    "/doctors",
    "/admin/users",
    "/admin/new",
    "/admin/patient",
    "/diet-template",
    "/hc/patients",
    "/schedule-management",
    "/leave-management",
    "/doctor-panel",
    "/notifications"
  ],
  nutritionist: [
    "/hc",
    "/flareups",
    "/pso-flareups",
    "/chat-room",
    "/calls",
    "/diet-plan-queue",
    "/diet-template",
    "/non-working-days",
    "/callback-request",
    "/notifications"
  ],
  psychologist: [
    "/hc",
    "/flareups",
    "/pso-flareups",
    "/chat-room",
    "/calls",
    "/non-working-days",
    "/callback-request",
    "/notifications"
  ],
  "uv coach": ["/hc"],
  crm: [
    "/crm/patients",
    "/crm/assigned-patients",
    "/chat-room",
    "/calls",
    "/hc",
    "/non-working-days",
    "/notifications"
  ],
  doctor: ["/doctor-panel"],
  kam: ["/doctor-panel"],
};

export default function hasPermission(page, auth) {
    if (['/', '/login'].some((url) => url === page)) return true;
    const routes = permittedRoutes[auth?.role];
    return routes.some((r) => page.startsWith(r));
}
